<template>
    <a href="https://www.linkedin.com/in/lee-proctor-ba530318/" target="_blank" rel="noopener noreferrer" 
      class="text-xl flex items-center justify-center mt-2 mb-2 px-6 py-3 text-gray-800 dark:text-white bg-blue-600 rounded-lg shadow-lg
           hover:bg-blue-700 transition duration-300 ease-in-out transform hover:scale-105 relative overflow-hidden">

        <span class="absolute inset-0 bg-gradient-to-r from-yellow-400 via-pink-500 to-blue-500 opacity-75"></span>
        <span class="absolute inset-1 bg-white dark:bg-black rounded-lg"></span>
        <span class="relative flex items-center justify-center">
          <svg class="w-5 h-5 mr-2 fill-current text-gray-800 dark:text-white inline-block"
            xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
            <path
              d="M22.23 0H1.77A1.77 1.77 0 000 1.77v20.46A1.77 1.77 0 001.77 24h20.46A1.77 1.77 0 0024 22.23V1.77A1.77 1.77 0 0022.23 0zM7.09 20.45H3.56V9h3.53v11.45zM5.32 7.59a2.05 2.05 0 110-4.1 2.05 2.05 0 010 4.1zm14.76 12.86h-3.52v-5.84c0-1.39-.02-3.17-1.93-3.17-1.94 0-2.24 1.52-2.24 3.07v5.94H8.85V9h3.38v1.56h.05a3.7 3.7 0 013.33-1.83c3.56 0 4.22 2.34 4.22 5.38v6.34z" />
          </svg>
          Connect on LinkedIn
        </span>
      </a>
  </template>
  

  