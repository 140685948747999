<template>
  <div class="container max-w-6xl mx-auto p-6 rounded-lg shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] bg-white bg-opacity-90">

    <h1 class="text-xl font-semibold mb-4">
      Employment History
    </h1>

    <!-- Job 1 DD -->
    <div class="mb-6">
      <h3 class="text-xl font-semibold">A full stack web developer - custom business software</h3>
      <h4 class="text-lg text-gray-700">Guntrader</h4>
      <p class="mb-2">
        A return to the Guntrader team after redundancy from previous role, 
        working on the new website and software alongside an external team. 
        A return to GT has enabled me to offer my prior experience to the team, assiting in shaping the future of 
        the software, at the same time as gaining further experience in Laravel and Vue.
      </p>
      <p class="text-gray-500">March 2024 - Present</p>
    </div>

    <!-- Job 1 DD -->
    <div class="mb-6">
      <h3 class="text-xl font-semibold">Senior Developer - web and software agency</h3>
      <h4 class="text-lg text-gray-700">Dentons Digital</h4>
      <p class="mb-2">
        In my role as the senior developer, I enjoyed fulfilling various responsibilities, encompassing:
        Full-stack web development, Solutions architecture, Technical and team leadership.
      </p>
      <p class="text-gray-500">May 2022 - March 2024</p>
    </div>

    <!-- Job 2 AP -->
    <div class="mb-6">
      <h3 class="text-xl font-semibold">A full stack web developer - web and software agency</h3>
      <h4 class="text-lg text-gray-700">Aptitec Ltd</h4>
      <p class="mb-2">
        Full stack development of custom learning management software,
        for various clients. Responsibilities including, application
        architecture design, database and code security, framework
        modernisation. Using the latest Laravel framework for backend and front end development.
      </p>
      <h5 class="font-semibold">Achievements:</h5>
      <p class="mb-2">
        Development of custom LMS for end use by high profile clients, the software passing all security tests internal
        and external.
      </p>
      <p class="text-gray-500">September 2021 - May 2022</p>
    </div>

    <!-- Job 3 GT -->
    <div class="mb-6">
      <h3 class="text-xl font-semibold">A full stack web developer - custom business software</h3>
      <h4 class="text-lg text-gray-700">Guntrader.uk</h4>
      <p class="mb-2">
        Working primarily as a full stack developer. Working with external companies
        and contractors to upgrade and migrate our websites and software to managed cloud
        hosting. Monitoring website and software performance, rewriting application
        code in Javascript and PHP for better performance. Optimizing database architecture
        and amending MySQL queries to increase performance for end users. Planning and
        developing our software to use modern frameworks including Laravel.
      </p>
      <h5 class="font-semibold">Achievements:</h5>
      <p class="mb-2">
        Significantly reducing the query execution time on key parts of the company software, which was heavily
        impacting user experience.
        Planning and prototyping a completely new version of the software, utilizing Laravel with a focus on design
        patterns for maintainability and unit testing.
      </p>
      <p class="text-gray-500">July 2020 - Aug 2021</p>
    </div>

    <!-- Job 4 NCC -->
    <div class="mb-6">
      <h3 class="text-xl font-semibold">A full stack web developer - custom business software</h3>
      <h4 class="text-lg text-gray-700">CRIS / NCC</h4>
      <p class="mb-2">
        Primarily a full stack software developer for the custom business software
        and a range of websites, currently deployed across a range of technical
        tasks and projects, also supporting other areas from project management
        to testing and documentation. Frequently using a range of systems and
        frameworks to support the business including but not limited to,
        Codeigniter, Laravel, and WordPress.
      </p>
      <h5 class="font-semibold">Achievements:</h5>
      <p class="mb-2">
        Successfully produced microsites for the marketing department,
        to a high standard within a very short time frame, to be used during
        the onset of the 2020 covid-19 pandemic to communicate to its industry members.
        The successful planning and joint presenting of new software to the senior
        members of the company, achieving the successful sign-off, for initial
        prototyping and resourcing of the software.
      </p>
      <p class="text-gray-500">2019 - 2020</p>
    </div>

    <!-- Job 6 PT-->
    <div class="mb-6">
      <h3 class="text-xl font-semibold">A full stack web developer - tech/pharmaceutical startup company</h3>
      <h4 class="text-lg text-gray-700">Pilltime Limited</h4>
      <p class="mb-2">
        A full stack web developer, in a tech/pharmaceutical startup company in Bristol.
        Working with legacy PHP systems and helping to develop modern microservice based architectures.
        Utilizing Laravel and Lumen PHP platforms, running Docker containers with AWS services.
        Unit testing and continuous integration, using Jenkins and Kubernetes.
        Writing documentation for user acceptance testing and release notes using Confluence.
        Agile software planning and task prioritizing using Jira. Producing software reports
        and statistics for senior board members. Leading daily developer standups,
        assisting and mentoring junior developers.
      </p>
      <h5 class="font-semibold">Achievements:</h5>
      <p class="mb-2">
        Working alongside the CEO and being given the opportunity to have and perform
        the scrum master responsibilities for the development team.
        The development and integration of a task management API, designed to
        help the pharmacy and customer support team track patient requests.
      </p>
      <p class="text-gray-500">2017 - 2018</p>
    </div>

    <!-- Job 7 RM -->
    <div class="mb-6">
      <h3 class="text-xl font-semibold">A full stack web developer - fast paced marketing agency</h3>
      <h4 class="text-lg text-gray-700">RACS Marketing</h4>
      <p class="mb-2">
        Overseeing and maintaining all company/client websites,
        using the latest HTML, Javascript, and PHP standards,
        utilizing Laravel and WordPress frameworks.
        Planning and requirements gathering for all company/client websites.
        Hosting and server requirements planning for production and development
        environments, including version control and backups. Leading and training
        new and junior team members, by helping to break down large pieces of work
        into modular reusable code blocks, leading and championing agile software
        development practices, instigating regular developer standups.
        Providing feedback to the designers regarding possible UI and UX issues.</p>

      <p class="text-gray-500">2016 - 2017</p>
    </div>

    <!-- Job 8 DON-->
    <div class="mb-6">
      <h3 class="text-xl font-semibold">Web developer - international advertising agency</h3>
      <h4 class="text-lg text-gray-700">Donor UK</h4>
      <p class="mb-2">
        Front end development of client websites, using the latest responsive
        HTML, CSS and modern Javascript. Development of client email campaigns.
        User and browser testing of clients websites and emails.
        Participating in daily developer stand up meetings.</p>
      <p class="text-gray-500">2015 - 2016</p>
    </div>

    <!-- Job 9 BM-->
    <div class="mb-6">
      <h3 class="text-xl font-semibold">Web Developer - web and software agency</h3>
      <h4 class="text-lg text-gray-700">Boson Media</h4>
      <p class="mb-2">
        Front and backend development of client websites, using
        HTML, CSS, PHP, and Javascript, utilizing WordPress and Zend frameworks.
        Planning and requirements gathering for client websites.
        Functional and browser testing of client websites.
      </p>
      <p class="text-gray-500">2014 - 2015</p>
    </div>

  </div>



</template>
