<template>
    <div class="justify-center items-center flex-grow">
      <iframe
        :src="`https://open.spotify.com/embed/playlist/${trackId}`"         
        width="100%"
        height="80"
        frameborder="0"
        allowtransparency="true"
        allow="encrypted-media"
      ></iframe>
    </div>
  </template>
  
<script>
export default {
  data() {
    return {
      trackId: '4ANPW38qMEYQ3Z1mVLrtmm'
    };
  }
};
</script>

  