<template>

  <div id="app" class="min-h-screen flex flex-col" :style="backgroundStyle">

    <header class="bg-white p-4 dark:bg-black  ">
      <nav class="flex justify-between items-center">
        <div class="text-grey-700 dark:text-white">Lee Proctor</div>
        
        <div class="flex space-x-4 text-grey-700 dark:text-white">
          <router-link to="/" class="hover:text-gray-300">Home</router-link>
          <router-link to="/work" class="hover:text-gray-300">Work</router-link>
          <router-link to="/cv" class="hover:text-gray-300">CV</router-link>
        </div>

        <div>
          <button @click="toggleTheme" class="text-gray-800 dark:text-white transition">
            <svg v-if="isDarkMode" class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M12 3v1m0 16v1m9-9h-1M4 12H3m15.364 6.364l-.707-.707M6.343 6.343l-.707-.707m12.728 0l-.707.707M6.343 17.657l-.707.707M16 12a4 4 0 11-8 0 4 4 0 018 0z">
              </path>
            </svg>
            <svg v-else class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                d="M20.354 15.354A9 9 0 018.646 3.646 9.003 9.003 0 0012 21a9.003 9.003 0 008.354-5.646z"></path>
            </svg>
          </button>
        </div>
      </nav>



    </header>

    <main class="flex-grow p-4 flex items-center justify-center">
      <router-view />
    </main>

    <footer class="bg-black bg-opacity-75 md:bg-opacity-25 text-white p-2 mt-auto 
    flex flex-col lg:flex-row lg:justify-between lg:items-center lg:gap-2">
      <div class="sm:block md:hidden">
        <LinkedinButton />
      </div>

      <SpotifyPlayer v-show="showSpotify" trackId="5uBKhKWTJ4E47rcLQqu3YH" />

      <SpotifyButton :show-spotify="showSpotify" @toggle-spotify="toggleSpotify"
      class="fixed top-16 -right-2 z-50" />
      
      <!-- <button @click="showSpotify = !showSpotify" 
      class="bg-black hover:bg-gray-800 text-white text-xs py-2 rounded-lg border
       border-white transition-colors duration-200 shadow-md px-2 h-8 ">
        {{ showSpotify ? 'Hide' : 'Show' }} Spotify Player
      </button> -->

      <!-- <TimerComp/> -->
    </footer>

  </div>
</template>

<script>
// Import the SpotifyPlayer component
import SpotifyPlayer from './components/SpotifyPlayer.vue';
import LinkedinButton from './components/LinkedinButton.vue';
import SpotifyButton from './components/SpotifyButton.vue';
// import TimerComp from './components/TimerComp.vue';

export default {
  name: "App",
  components: {
    SpotifyPlayer,  // Register the Spotify Player globally
    // TimerComp,  // Register the Spotify Player globally
    LinkedinButton,
    SpotifyButton,
  },

  data() {
    return {
      isDarkMode: false,
      showSpotify: false
    };
  },

  mounted() {
    // Check user's system preference for dark mode on mount
    this.isDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    this.setTheme();
  },
  methods: {
    toggleTheme() {
      this.isDarkMode = !this.isDarkMode;
      this.setTheme();
    },
    
    setTheme() {
      if (this.isDarkMode) {
        document.documentElement.classList.add('dark');
      } else {
        document.documentElement.classList.remove('dark');
      }
    },

    toggleSpotify() {
      this.showSpotify = !this.showSpotify;
    }

  },

  computed: {
    backgroundStyle() {
      return {
        background: this.isDarkMode
          ? `linear-gradient(125deg,
              black 35%,
              yellow 35%, yellow 39.5%,
              black 39.5%, black 40%,
              magenta 40%, magenta 44.5%,
              black 44.5%, black 45%,
              cyan 45%, cyan 49%,
              black 49%)`
          : `linear-gradient(125deg,
              white 35%,
              yellow 35%, yellow 39.5%,
              white 39.5%, white 40%,
              magenta 40%, magenta 44.5%,
              white 44.5%, white 45%,
              cyan 45%, cyan 49%,
              white 49%)`,
        backgroundAttachment: 'fixed',
        backgroundSize: 'cover',
        minHeight: '100vh',
        margin: 0,
      };
    },
  },


};
</script>

<style>
/* Ensure the footer is sticky */
footer {
  position: sticky;
  bottom: 0;
}
</style>./components/TimerComp.vue
